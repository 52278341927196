import { graphql, Link, withPrefix } from 'gatsby'
import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from "react-helmet";
import WorldgradJourneyUG from '../components/WorldgradJourneyUG';
import WorldgradJourneyPG from '../components/WorldgradJourneyPG';
import PopupForm from "../components/popupForm";
import PopupVideo from "../components/popupVideo";
import PopularDegreeOutcome from "../components/popular-degree-outcome-new";
import Swiper, { Navigation, Pagination, Autoplay,  Mousewheel} from 'swiper';
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';
import placeholder from '../images/placeholder.png';
import "../css/swiper-bundle.min.css";
Swiper.use([Navigation, Pagination, Autoplay, Mousewheel]);

const _ = require(`lodash`);


const UniversityDetailPage = ({ data, pageContext, path}) => { 
  //console.log("Page Context:", pageContext.popularDegrees);

    const title  = data.wordpressWpUniversities.acf.university_name

    const shorelightUniversities = [];

    const country = data.wordpressWpUniversities.acf.country

    let entryPathLink = ""
    let entryPathText = ""

    const uniInfo = ReactHtmlParser(data.wordpressWpUniversities.content)
    const academicQualification = ReactHtmlParser(data.wordpressWpUniversities.acf.academic_qualification)
    const feesAndScholarship = ReactHtmlParser(data.wordpressWpUniversities.acf.fees_and_scholarship)
    const englishRequirements = ReactHtmlParser(data.wordpressWpUniversities.acf.english_requirements)
    const coursesInfo = data.wordpressWpUniversities.acf.courses_info
    const uniRank = ReactHtmlParser(data.wordpressWpUniversities.acf.rank)
    const rankType = ReactHtmlParser(data.wordpressWpUniversities.acf.rank_type)

    const post = data.wordpressWpUniversities;
    const popularDegrees = data.allWordpressWpPogramlist.edges;

	const metatitle = post.acf.meta_title ;
	const metadescription = post.acf.meta_description; 
    const focus_keyphrase = post.acf.focus_keyphrase; 
    const canonical_url = `https://theworldgrad.com${path}`; 
    let countryname;

switch (post.acf.country) {
  case 'United States of America':
    countryname = 'usa';
    break;
  case 'United Arab Emirates':
    countryname = 'uae';
    break;
  default:

    countryname = post.acf.country;
    break;
}


    const [toggleClass, setToggleClass] = useState(false)
    const [activeTab, setActiveTab] = useState('undergraduate');



    const embedUrl = data.wordpressWpUniversities.acf.video_url;
    let videoThumb;
    
    if (embedUrl && embedUrl.includes("https://www.youtube.com/embed/")) {
        const videoId = embedUrl.match(/embed\/(.+)/)[1];  
        videoThumb = "https://img.youtube.com/vi/" + videoId + "/hqdefault.jpg";
    } else {
        videoThumb = placeholder;
    }



    const toggleTab = (tabName) => {
      setActiveTab(tabName);
    };

    const handleTextToggle = () => {
        setToggleClass(!toggleClass)
    }

    if(title === "UOW College Australia / University of Wollongong") {
        entryPathLink = "global-online-accelerator-program"
        entryPathText = "Global Online Accelerator Program"
    } else if (country === "USA") {
        entryPathLink = "all-american-undergraduate-program"
        entryPathText = "All American Undergraduate Program"
    } else {
        entryPathLink = "unismarter"
        entryPathText = "UniSmarter"
    }

    const [time, setTime] = useState(9000000000000)

    useEffect(() => {
        setTime(9000000000000);
      
    const swiper = new Swiper('.rightchoice-slider', {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        watchOverflow: true,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination-rightchoice',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-next-rightchoice',
          prevEl: '.swiper-prev-rightchoice',
        },
        breakpoints: {
          990: { slidesPerGroup: 3, slidesPerView: 3 },
          768: { slidesPerGroup: 2, slidesPerView: 2 },
          576: { slidesPerGroup: 1, slidesPerView: 1 }
        }
      });


  
      }, [])
    
    const handlePopupWindow = () => {
        setTime(Math.random())
    }
    

    
    return (
        <Layout>
            <Helmet> 
            <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
</Helmet>

            <PopupForm time={time} />
                
                <div class="newStyle font-poppins university-details">

                <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
                                            <li class="breadcrumb-item">
                                            <a href={`/countries/study-in-${_.kebabCase(countryname)}`}>{post.acf.country}</a>
											</li>
											<li class="breadcrumb-item active"> {ReactHtmlParser(post.title) } </li>
										</ol>
				</div>
 
  <section class="wow animate__fadeIn bg-light-gray  d-flex align-items-center position-relative extra-big-section unibanner" style={{backgroundImage: `url(${data.wordpressWpUniversities.acf.banner_image ? data.wordpressWpUniversities.acf.banner_image.source_url : 'none'
})`}}  >

        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
	
                    <div class="alt-font text-white no-margin-bottom" >
             
                    </div>

                </div>
            </div>
        </div>
    </section>   

    

    </div>
    <section style={{ backgroundColor: "#f8f8fa" }} class="p-0">
                    <div className="row align-items-center mx-1 mx-md-5 py-0" >
                        
                        <div className="col-12 text-center mb-0">
                            <div className='blended' style={{backgroundImage: `url(${data.wordpressWpUniversities.acf.university_logo ? data.wordpressWpUniversities.acf.university_logo.source_url : 'none'
})`}}  ></div>
                            
                        </div>

                        {
                            shorelightUniversities.includes(title) ? (
                                <div className="col-12 text-center mt-3 mb-3">
                                    <img style={{ width: "100px" }} src="https://assets.theworldgrad.com/gatsby-assets/xtra/SL_Collaboration_Stacked-1.png" alt="Shorelight" />
                                </div>
                            ) : (
                                ''
                            )
                        }
                    </div>
                </section>

             <div className="px-0 " style={{backgroundColor: "#fff", overflowX: "hidden"}}>






      {data.wordpressWpUniversities.acf.after_banner_blocks?.length > 0 ? (
        <section className='half-section newStyle'> 
          <div className="container">
            <div className="row mx-0 justify-content-between university-detail-features-container my-4">
              {data.wordpressWpUniversities.acf.after_banner_blocks?.map((block, index) => (
                <div key={index} className="col-md-5 university-detail-features d-flex align-items-center">
                  <div>
                    <h5 className='font-weight-500'>{block.title}</h5>
                    <p>{ReactHtmlParser(block.detail)}</p>
                    <img
                      className="university-detail-features-svg"
                      src={block?.icon?.source_url}
                      alt={`${block.title} icon`}
                    />
                  </div>
                </div>
              ))}
              <div className="col-12 text-center mt-2">
                <p className="mb-0"> 
                  <i>
                    {rankType}
                  </i>
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='half-section newStyle'> 
          <div className="container">
            <div className="row mx-0 justify-content-between university-detail-features-container my-4">
              <div className="col-md-5 university-detail-features d-flex align-items-center">
                <div>
                  <h5 className='font-weight-500'> Location </h5>
                  <p> {data.wordpressWpUniversities.acf.location}, {data.wordpressWpUniversities.acf.country}  </p>
                  <img
                    className="university-detail-features-svg"
                    src="https://assets.theworldgrad.com/gatsby-assets/xtra/Group.svg"
                    alt="Location SVG"
                  />
                </div>
              </div>

              <div className="col-md-5 university-detail-features d-flex align-items-center">
                <div>
                  <h5 className='font-weight-500'> Rank* </h5>
                  <p> {uniRank}</p>
                  <img
                    className="university-detail-features-svg"
                    src="https://assets.theworldgrad.com/gatsby-assets/xtra/star.svg"
                    alt="Rank SVG"
                  />
                </div>
              </div>

              <div className="col-md-5 university-detail-features d-flex align-items-center">
                <div>
                  <h5 className='font-weight-500'> University Fees </h5>
                  <p> 
                    {data.wordpressWpUniversities.acf.direct_education_cost}
                  </p>
                  <p> 
                    {data.wordpressWpUniversities.acf.direct_education_cost_secondary}
                  </p>
                  <img
                    className="university-detail-features-svg"
                    src="https://assets.theworldgrad.com/gatsby-assets/xtra/money-bag.svg"
                    alt="Fees SVG"
                  />
                </div>
              </div>

              <div className="col-md-5 university-detail-features d-flex align-items-center">
                <div>
                  <h5 className='font-weight-500'> Entry Path </h5>
                  <p><a href={data.wordpressWpUniversities.acf.entry_path_url}>{data.wordpressWpUniversities.acf.entry_criteria}</a></p>
                  {data.wordpressWpUniversities.acf.entry_criteria_secondary && (
                    <p><a href={data.wordpressWpUniversities.acf.entry_path_url_secondary}>{data.wordpressWpUniversities.acf.entry_criteria_secondary}</a></p>
                  )}
                  <img
                    className="university-detail-features-svg"
                    src="https://assets.theworldgrad.com/gatsby-assets/xtra/way.svg"
                    alt="Entry Path SVG"
                  /> 
                </div>
              </div>

              <div className="col-12 text-center mt-2">
                <p className="mb-0"> 
                  <i>
                    {rankType}
                  </i>
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
 
 
<div className='newStyle'>



<section className='half-section bg-light-gray about-uni '>
                 <div className="container newStyle">
                <div className="row  mx-0 ">
                    
                    <div class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6  mx-0 p-0 position-relative wow" data-wow-delay="0.1s" style={{backgroundImage: "url("+videoThumb+")"}} >
<img src={videoThumb} alt="YouTube" data-no-retina=""  class="br-15 margin15 opacity-0 " />
	<div class="opacity-very-light bg-medium-slate-blue br-15 "></div>
    <PopupVideo time={Math.random()} videoURL={data.wordpressWpUniversities.acf.video_url}/> 

                    </div>
                    <div className="col-lg-6 content-holder video-section-content-holder-right margin15 mt-5 mt-lg-0">
                        <h2 className='text-center text-md-left'> About {title} </h2> 

                        <div className=' text-left'>
                            {uniInfo}
                        </div>

                        <div className="row mt-2">
                            <div className="col-12 sm-text-center">
                            
                                <Link
                                    to={data.wordpressWpUniversities.acf.website_url}
                                    class="btn btn-theme effect btn-md modal-popup"
                                    data-animation="animated zoomInUp"
                                    data-wow-delay=".1s"
                                    target="_blank"
                                >
                                    Visit University Website
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
</div>
</section>

{post.acf.key_facts_section?.key_facts?.length > 0 && (
  <section className="key-facts-section half-section wow animate__fadeIn"> 
    <div className="container">
      <div className="row">
      <div className="col-md-12 text-center">
      <h2>{post.acf.key_facts_section.key_fact_title}</h2>
      </div>
  
        <div className="d-none d-xs-block col-md-5 text-center mb-4">
          <img src={post.acf.key_facts_section?.map?.source_url} alt="Map" />
        </div>
        <div className="col-md-7">
         
          <ul className="p-0 list-style-02">
            {post.acf.key_facts_section?.key_facts?.map((fact, index) => {
              return (
                <li key={index}>
                  <img src={fact?.icon?.source_url} alt="->" data-no-retina="" className="icon-image" />
                  {ReactHtmlParser(fact.detail)}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-md-5 d-none-991 text-center">
          <img src={post.acf.key_facts_section.map?.source_url} alt="Map" />
        </div>
      </div>
    </div>
  </section>

)}
{post.acf.right_choice_section?.right_choice_item?.length > 0 && (
<section class="half-section bg-light-gray wow animate__fadeIn"> 
            <div class="container">
				<div class="row">
                    <div class="col-md-10 offset-md-1 text-center margin-two-bottom">
                        <h2 >{post.acf.right_choice_section.right_choice_title}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                <div class=" rightchoice-slider swiper-container swiper-style-new-design pb-3 pb-lg-0">
                <div class="swiper-wrapper">
				{post.acf.right_choice_section.right_choice_item.map((item, index) => {
									return (
                                        <div class="swiper-slide ">
										<div className="col-sm-12 col-12 col-lg-12 xs-margin-15px-bottom wow animate__fadeIn swiper-slide ">
											<div className="feature-box br-15 h-100 feature-box-left-icon bg-white box-shadow-small box-shadow-extra-large-hover overflow-hidden padding-2-rem-all">
												
										
												<div className="feature-box-content last-paragraph-no-margin text-justify">
													<div class="text-center" style={{paddingBottom: '15px'}}><img src={item.right_choice_item_icon?.source_url} alt="✓"  /></div>
							
													<p className="alt-font font-weight-500 margin-10px-bottom d-block text-center text-extra-dark-gray font-size-18">{item.right_choice_item_title}</p>
													{ReactHtmlParser(item.right_choice_item_content)} 
												</div>
													
											</div>
										</div>
                                     </div>   
									);
								})}
             </div>
             
<div className="swiper-pagination swiper-pagination-rightchoice"></div>
    <div class="swiper-button-next swiper-next-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowRightSolidIcon} alt="->" className="left-icon newicon newicon-black left2pxtop0px" /></div>
	<div class="swiper-button-prev swiper-prev-degree rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black left2pxtop0px" /></div>
    </div>
                </div>
            </div>
        </section>
)}


<section class="half-section  uk-program-process wow animate__fadeIn pb-lg-5" > 
<div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-12 text-center margin-one-bottom">
          <h2>{data.wordpressWpUniversities.acf.twg_journey_header}</h2>
        </div>
      </div>
{data.wordpressWpUniversities.acf.worldgrad_journey_for_postgraduate && data.wordpressWpUniversities.acf.worldgrad_journey_for_postgraduate.year_one_program != "" &&
  data.wordpressWpUniversities.acf.worldgrad_journey && data.wordpressWpUniversities.acf.worldgrad_journey.year_one_program != "" && (
    
         <div className="row border-top border-width-1px border-color-medium-gray">
    <div className="col-12 p-0 tab-style-07  margin-5-rem-bottom lg-margin-4-rem-bottom border-bottom border-color-medium-gray md-margin-3-rem-bottom">
    <ul className="nav nav-tabs justify-content-center text-center text-uppercase font-weight-500 alt-font">
      <li className={`nav-item ${activeTab === 'undergraduate' ? 'active' : ''}`} onClick={() => toggleTab('undergraduate')}>
      <a className="nav-link">
      {
data.wordpressWpUniversities.acf.worldgrad_journey.journey_label_1 
? data.wordpressWpUniversities.acf.worldgrad_journey.journey_label_1 
: 'Undergraduate'
}
</a>
      </li>
      <li className={`nav-item ${activeTab === 'postgraduate' ? 'active' : ''}`} onClick={() => toggleTab('postgraduate')}>
        <a className="nav-link">
    {
data.wordpressWpUniversities.acf.worldgrad_journey_for_postgraduate.journey_label_2
? data.wordpressWpUniversities.acf.worldgrad_journey_for_postgraduate.journey_label_2 
: 'Postgraduate'
}
</a>
      </li>
    </ul>
  </div>
  </div>
  )}
        
      
      <div className="container">
        <div className="tab-content">

        {data.wordpressWpUniversities.acf.worldgrad_journey && (
         <div id="undergraduate_tab" className={`tab-pane fade ${activeTab === 'undergraduate' ? 'in show active' : ''}`}>
         <WorldgradJourneyUG uniData={data.wordpressWpUniversities} />
         </div>
  )}

         
      
  {data.wordpressWpUniversities.acf.worldgrad_journey_for_postgraduate && (
        <div id="postgraduate_tab" className={`tab-pane fade ${activeTab === 'postgraduate' ? 'in show active' : ''}`}>
    <WorldgradJourneyPG uniData={data.wordpressWpUniversities} />
    </div>
  )}

        </div>
      </div>
    </div>
        </section>
        {post.acf.popular_degree_outcome && post.acf.popular_degree_outcome.length > 0 && (
  <section className="half-section wow animate__fadeIn bg-light-gray">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1 text-center margin-two-bottom">
          <h2>{data.wordpressWpUniversities.acf.course_header}</h2>
        </div>
      </div>
      <PopularDegreeOutcome
  degrees={pageContext.popularDegrees } popularDegrees= {popularDegrees}
/>
                    

      

      <div className="text-center mt-4">
        <a
          data-animation="animated zoomInUp"
          className="btn btn-theme effect btn-md"
          href={"/program-listing/?key=" + data.wordpressWpUniversities.title.replace("'", "%27").replace("’", "%27").split('/')[0].split('(')[0].trim()}
        >
          Explore More Degrees
        </a>
      </div>
    </div>
  </section>
)}

</div>
            </div>
        </Layout>
    )
}

export default UniversityDetailPage

export const query = graphql`
    query UniversityInfo($name: String, $popularDegrees: [Int]) {
        wordpressWpUniversities(slug: {eq: $name}) {
            id
            wordpress_id
            slug
            content
            title
            acf {
                sub_header_text
                academic_qualification
                banner_image {
                  source_url
                }
                banner_image_mobile {
                    source_url
                }
                university_thumbnail {
                    source_url
                }
                country
                course_header
                direct_education_cost
                english_requirements
                entry_criteria
                entry_path_url
             
             
                fees_and_scholarship
                location
                rank
                rank_type
                meta_title
                meta_description
                focus_keyphrase
                canonical_url
                dynamic_schema 
                twg_journey_header
                university_logo {
                  source_url
                }
                university_name
                popular_degree_outcome
                video_url
                website_url

              
                direct_education_cost_secondary
                 entry_criteria_secondary
                 entry_path_url_secondary
                worldgrad_journey {
                journey_label_1
                    year_1_study_mode
                    year_1_term_2
                    year_1_term_2_study_mode
                    year_1_trem_2_program_provider
                    year_one_program
                    year_one_program_link
                    year_one_program_provider
                    year_two_
                    year_two_program
                    year_two_study_mode
                    year_one_credit
                    year_1_term_2_credit
                    year_two_credit
                }
                worldgrad_journey_for_postgraduate {
                journey_label_2
                    year_one_program
                    year_one_program_link
                    year_one_program_provider
                    year_1_study_mode
                    year_1_term_2
                    year_1_trem_2_program_provider
                    year_1_term_2_study_mode
                    year_two_program
                    year_two_
                    year_two_study_mode
                    year_one_credit
                    year_1_term_2_credit
                    year_two_credit
                  }
             after_banner_blocks {
          detail
          title
          icon {
            source_url
          }
          title
          detail


        }
        key_facts_section {
          key_fact_title
          key_facts {
            detail

                        icon {
source_url
            }
          }
          map {
            source_url
          }
        }
        right_choice_section {
          right_choice_title
          right_choice_item {
            right_choice_item_content
            right_choice_item_title
            right_choice_item_icon {
              source_url
            }
          }
        }
                  
              
            }
        }
            allWordpressWpPogramlist(filter: { wordpress_id: { in: $popularDegrees } }) {
     edges {
      node {
        title
        slug
        wordpress_id
        featured_media {
          source_url
        }
        acf {
          university_logo {
            source_url
          }
                 select_university {
  
            post_name
          
              
          }
          university_ranking
          program_dudation
          short_eligibility
          term_1
          term_2
          year_2_and_3
          featured
          featured_order
          degree_type
          country
          you_save
          term_1_title
          term_2_title
          term_3_title
        }
      }
    }
    }

    }
`
